import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(" Exportação Folha ")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"warning","data-test":"button-export","outlined":"","disabled":_vm.disableExportButton},on:{"click":function($event){return _vm.exportCsv()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_c('span',[_vm._v(" Exportar ")])],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openFilterModal()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlusOutline)+" ")]),_vm._v(" Filtros ")],1),_c(VBtn,{staticClass:"ml-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.clearFilter()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_vm._v(" Limpar filtros ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loading,"sort-by":'user.name',"sort-desc":false,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")]}},{key:"item.event.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event.code)+" - "+_vm._s(item.event.name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}],null,true)}),_c(VDialog,{attrs:{"width":"700px","persistent":""},model:{value:(_vm.showFilterModal),callback:function ($$v) {_vm.showFilterModal=$$v},expression:"showFilterModal"}},[_c('FilterModal',{attrs:{"eventList":_vm.eventList,"employeesList":_vm.employeesList,"listOfCompanies":_vm.listOfCompanies},on:{"setDataFromFilterInTable":function ($event) { return _vm.setDataFromFilterInTable($event); },"close":function($event){_vm.showFilterModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }