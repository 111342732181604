<template>
  <v-card>
    <v-card-title>
      <span> Exportação Folha </span>

      <v-spacer />

      <v-btn
        class="mr-2"
        color="warning"
        data-test="button-export"
        outlined
        :disabled="disableExportButton"
        @click="exportCsv()"
      >
        <v-icon size="30" class="me-2">
          {{ icons.mdiDownload }}
        </v-icon>
        <span> Exportar </span>
      </v-btn>

      <v-btn color="info" class="ml-4" outlined @click="openFilterModal()">
        <v-icon size="30" class="me-2">
          {{ icons.mdiFilterPlusOutline }}
        </v-icon>
        Filtros
      </v-btn>

      <v-btn class="ml-4" outlined @click="clearFilter()">
        <v-icon size="30" class="me-2">
          {{ icons.mdiFilterRemoveOutline }}
        </v-icon>
        Limpar filtros
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      :sort-by="'user.name'"
      :sort-desc="false"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template #[`item.value`]="{ item }">
        {{ Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template #[`item.event.name`]="{ item }"> {{ item.event.code }} - {{ item.event.name }} </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>

    <v-dialog v-model="showFilterModal" width="700px" persistent>
      <FilterModal
        :eventList="eventList"
        :employeesList="employeesList"
        :listOfCompanies="listOfCompanies"
        @setDataFromFilterInTable="$event => setDataFromFilterInTable($event)"
        @close="showFilterModal = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDownload, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline } from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import FilterModal from './FilterModal.vue'
const ExcelJS = require('exceljs')

export default {
  components: {
    FilterModal,
  },

  mixins: [formatters, messages],

  data() {
    return {
      headers: [
        { text: 'PROTOCOLO', value: 'protocol', align: 'left', sortable: false },
        { text: 'DIGITADOR', value: 'user_created.name', align: 'left', sortable: false },
        { text: 'COLABORADOR', value: 'user.name', align: 'left', sortable: false },
        { text: 'EVENTO', value: 'event.name', align: 'left', sortable: false },
        { text: 'VALOR', value: 'value', align: 'left', sortable: false },
        { text: 'REFERÊNCIA', value: 'reference', align: 'center', sortable: false },
        { text: 'FILIAL', value: 'fantasy_name', align: 'center', sortable: false },
      ],

      eventList: [],
      itemsTable: [],
      employeesList: [],
      commissionList: [],
      listOfCompanies: [],
      listOfFilteredItems: [],

      loading: false,
      loadingExport: false,
      showFilterModal: false,
      disableExportButton: true,

      icons: {
        mdiEye,
        mdiDownload,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  created() {
    this.employeesList = localStorageSlim.get('leaderList', { decrypt: true })

    this.getEventList()
    this.getListOfCompanies()
  },

  /**
   * @typedef {Object} Events
   * @property {string} id
   * @property {number} code
   * @property {string} name
   * @property {number} totalValue
   * @property {string} fantasyName
   */

  /**
   * @typedef {Object} Data
   * @property {number} codSelf
   * @property {Object.<string, Events>} events
   * @property {string} fantasyName
   */

  /**
   * An array with the total value for each event of each user.
   *
   * @typedef {Object} UserValuesByEvent
   * @property {string} name - The user name.
   * @property {Data} data - The user's event data including event codes, names, IDs and total value.
   */
  methods: {
    async getEventList() {
      await axiosIns.get('api/v1/integration/event/index').then(response => {
        response.data.data.map(({ id, name, code }) => {
          this.eventList.push({
            id,
            name: `${code} - ${name}`,
            nameWithoutCode: name,
            code,
          })
        })

        this.eventList.sort(({ code: firstCode }, { code: secondCode }) => firstCode - secondCode)
      })
    },

    async getListOfCompanies() {
      await axiosIns.get('api/v1/records/company/index').then(response => {
        this.listOfCompanies = response.data.data
      })
    },

    async exportCsv() {
      const items = this.itemsTable
      const totalValuesPerEvent = this.getTotalValuesPerEvent(items)

      // Get the CSV file content for the current totalValuesPerEvent result and store it in a variable
      const file = await this.getCsv(totalValuesPerEvent)

      saveAs(file)
    },

    /**
     * Calculates the total value for each event of each user.
     *
     * @param {Array.<object>} users - An array of objects containing user information, event details, and values.
     * @returns {Array.<UserValuesByEvent>} An object with the total value for each event of each user.
     */
    getTotalValuesPerEvent(users) {
      const usersValuesByEvent = {}

      users.forEach(user => {
        const {
          user: { name, cod_self },
          fantasy_name,
          event,
          value,
        } = user
        const eventId = event.id
        const eventName = event.name
        const eventCode = event.code

        if (!usersValuesByEvent[name]) {
          usersValuesByEvent[name] = {
            codSelf: cod_self,
            events: {
              fantasyName: fantasy_name,
              [eventCode]: {
                id: eventId,
                name: eventName,
                code: eventCode,
                totalValue: Number(value),
              },
            },
          }
        } else {
          if (!usersValuesByEvent[name].events[eventCode]) {
            usersValuesByEvent[name].events[eventCode] = {
              id: eventId,
              name: eventName,
              code: eventCode,
              totalValue: 0,
            }
          }

          usersValuesByEvent[name].events[eventCode].totalValue += Number(value)
          usersValuesByEvent[name].fantasyName = fantasy_name
          usersValuesByEvent[name].codSelf = cod_self
        }
      })

      /**
       * An array of objects with the total value for each event of each user.
       *
       * @type {Array.<UserValuesByEvent>}
       */
      const listWithUsersValuesByEvent = Object.entries(usersValuesByEvent).map(([name, data]) => {
        return { name, data }
      })

      return listWithUsersValuesByEvent
    },

    /**
     * Generates an xlsx file based on a given array of objects containing the user's name and data, which includes a code, a fantasy name, and an object with the events and their total values.
     *
     * @param {Array.<UserValuesByEvent>} listWithUsersValuesByEvent - An Array of objects containing data about users and their events values to be exported as an xlsx file.
     * @returns {Promise<Blob>} - A Promise containing the generated xlsx file as a File object.
     */
    async getCsv(listWithUsersValuesByEvent) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')
      worksheet.getRow(1).font = { bold: true }

      const header = [
        { header: 'CodSelf', key: 'codSelf', width: 10 },
        { header: 'Funcionario', key: 'name', width: 10 },
        { header: 'Filial', key: 'fantasyName', width: 10 },
      ]

      // Iterates over the listWithUsersValuesByEvent array and adds the corresponding event columns to the header
      Object.values(listWithUsersValuesByEvent).forEach(({ data }) => {
        Object.values(data.events).forEach(event => {
          if (typeof event === 'object') {
            if (!header.some(h => h.key === `Evento ${event.code}`)) {
              header.push({ header: event?.name, key: `Evento ${event.code}` })
            }
          }
        })
      })

      for (let column of header) {
        column.width = column.header.length + 2
      }

      worksheet.columns = header

      // Adds a row for each object in the listWithUsersValuesByEvent array
      listWithUsersValuesByEvent.forEach(({ name, data }) => {
        const row = {
          codSelf: data.codSelf,
          name: name,
          fantasyName: data.fantasyName ?? data.events.fantasyName,
        }
        Object.values(data.events).forEach(event => {
          row[`Evento ${event.code}`] = event.totalValue || 0
        })

        worksheet.addRow(row)
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'conferencia-folha', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },

    openFilterModal() {
      this.showFilterModal = true
    },

    setDataFromFilterInTable(event) {
      this.itemsTable = event

      if (this.itemsTable) {
        this.disableExportButton = false
      }
    },

    clearFilter() {
      this.itemsTable = []
      this.disableExportButton = true
    },
  },
}
</script>
