<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="mb-4">Filtrar dados</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-dialog
          ref="dialogReferenceDate"
          v-model="showCalendarReferenceDate"
          :return-value.sync="referenceDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedReferenceDate"
              :append-icon="icons.mdiCalendar"
              label="Mês Referência"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="referenceDate" width="400px" locale="pt-BR" type="month" scrollable>
            <v-spacer />
            <v-btn text color="primary" @click="showCalendarReferenceDate = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.dialogReferenceDate.save(referenceDate)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-autocomplete
          v-model="selectedCompanyId"
          :items="listOfCompanies"
          item-text="fantasy_name"
          item-value="id"
          label="Filial"
          outlined
          dense
        />

        <v-autocomplete
          v-model="eventIdSelected"
          :items="eventList"
          item-text="name"
          item-value="id"
          label="Evento"
          outlined
          dense
        />

        <v-autocomplete
          v-model="typistUser"
          :items="employeesList"
          item-text="name"
          item-value="id"
          label="Digitador"
          outlined
          dense
        />

        <v-autocomplete
          v-model="employeeIdSelected"
          :items="employeesList"
          item-text="name"
          item-value="id"
          label="Colaborador"
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn color="error" @click="closeModal(), resetForm()"> Fechar </v-btn>

        <v-btn color="info" class="ml-5" @click="getFilterData()">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    employeesList: {
      type: Array,
      required: true,
    },

    eventList: {
      type: Array,
      required: true,
    },

    listOfCompanies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      typistUser: '',
      referenceDate: '',
      eventIdSelected: '',
      selectedCompanyId: '',
      employeeIdSelected: '',

      loadingCircle: false,
      showCalendarReferenceDate: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedReferenceDate: {
      get() {
        if (!this.referenceDate) return ''

        return this.formatDate(this.referenceDate)
      },

      set() {},
    },
  },

  methods: {
    async getFilterData() {
      const formIsValid = this.$refs.form.validate()

      const body = {
        reference: this.referenceDate,
        users_created_id: this.typistUser,
        users_id: this.employeeIdSelected,
        events_id: this.eventIdSelected,
        companies_id: this.selectedCompanyId,
      }

      if (formIsValid) {
        this.loadingCircle = true

        await axiosIns
          .post('api/v1/integration/typing_sheet/filter_leaf', body)
          .then(response => {
            const dataFiltered = response.data

            if (dataFiltered.length === 0) {
              this.showMessage({ title: 'Nenhum registro encontrado!', text: 'Tente novamente' })

              this.loadingCircle = false

              return
            }

            this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })

            this.sendFilterData(dataFiltered)
            this.loadingCircle = false
          })
          .catch(error => {
            this.showMessage({ title: 'Erro ao enviar dados', text: error, icon: 'error' })
          })

        this.resetForm()
        this.closeModal()

        return
      }

      this.showMessage({
        title: 'Filtros vazios',
        text: 'Verifique se algum dos campos está preenchido',
      })
    },

    sendFilterData(data) {
      if (typeof data !== 'object') {
        return
      }

      this.$emit('setDataFromFilterInTable', data)
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (date) {
        const [year, month] = date.split('-')

        return `${month}-${year}`
      }

      return
    },

    resetForm() {
      this.$refs.form.reset()
      this.referenceDate = ''
    },
  },
}
</script>
